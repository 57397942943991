<template>

</template>
<style scoped>
</style>

<script>
import axios from 'axios';
import { userchecktoken } from '@/api/user';
import Cookies from 'js-cookie';
import {getCookieByName,removeCookieByName} from '@/utils/helper';
export default {
  props: ['isnemsg'],
  data() {
    return {
      apptimer: null,
      value: 0,
    };
  },
  methods: {
    checktoken() {
      var that = this;
      let baseUrl = process.env.VUE_APP_URL3;
      var that = this;
      const token =getCookieByName('access_token');
      axios
        .get(
          `${baseUrl}/user/checktoken`,
          {
            params: { requestid: this.requestid, t: Math.random() },
             headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Bearer ' + token,
            },
          }
        )
        .then((res) => {
          if (res.data.code == 0 || res.data.code == 401) {
            clearInterval(that.apptimer);
            that.removeCookies();
            that.apptimer = null;
            var path = window.location.pathname.toLowerCase();
            if (path.indexOf('/user') > -1) {
              window.location.href = '/';
            }
            window.location.href = '/';
          }
        });
      if (this.isnemsg) {
        this.getmsg();
      }
    },
    getmsg() {
      this.$parent.onusermsgcount();
    },
    removeCookies() {
      removeCookieByName('access_token');
      removeCookieByName('user_account');
      removeCookieByName('refresh_token');
      removeCookieByName('user_id');
      removeCookieByName('iscompanyauthsuccess');
    },
  },

  mounted() {
    this.apptimer = window.setInterval(this.checktoken, 5000);
    var accessToken =getCookieByName('access_token'); 
    if (typeof accessToken === 'undefined' || accessToken == null) {
      clearInterval(this.apptimer);
      this.removeCookies();
      this.apptimer = null;
      var path = window.location.pathname.toLowerCase();
      if (path.indexOf('/user') > -1) {
        window.location.href = '/';
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.apptimer);
    this.apptimer = null;
  },
};
</script>